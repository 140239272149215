import {Component, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CESpinnerModule} from '@ce-lib/spinner';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {IUser} from 'src/app/shared/interfaces/user';
import {
  EventType as AppEventType,
  ApplicationInsightsService,
} from 'src/app/shared/services/appInsights/app-insights.service';
import {HelperService} from 'src/app/shared/services/helper/helper.service';
import {UsersService} from 'src/app/shared/services/users/users.service';
import {CONSTANTS} from '../../utils/constants/constants';
import {SetIsDirectReport} from '../../utils/state/jobs/jobs.action';
import {SaveUser} from '../../utils/state/user/user.action';
import {UserState} from '../../utils/state/user/user.state';
import {AuthService} from '../auth-service/auth.service';

@Component({
  selector: 'app-role-based-redirection',
  standalone: true,
  imports: [CESpinnerModule],
  templateUrl: './role-based-redirection.component.html',
  styleUrl: './role-based-redirection.component.scss',
})
export class RoleBasedRedirectionComponent implements OnInit {
  currentUser$: Observable<IUser> = inject(Store).select(
    UserState.getCurrentUser
  );
  user!: IUser;

  constructor(
    private _usersService: UsersService,
    private _authService: AuthService,
    private _helperService: HelperService,
    private _appInsightsService: ApplicationInsightsService,
    private _router: Router,
    private _store: Store
  ) {}

  ngOnInit() {
    const account = this._authService.activeAccountExist();
    this._usersService.getCurrentUser(this._authService.userEmail).subscribe({
      next: (user) => {
        this.user = {...user};
        this._store.dispatch(new SaveUser(user));
        this._store.dispatch(new SetIsDirectReport(true));
        this._appInsightsService.trackEvent(
          {name: CONSTANTS.EVENT_NAME.AUTHENTICATION_SUCCESS},
          {
            event: AppEventType.Authentication,
            action: CONSTANTS.EVENT_NAME.AUTHENTICATION_SUCCESS,
            page: CONSTANTS.TELEMETRY.FILENAME.APP_COMPONENT,
            user: this.user,
            email: this.user.employeeEmail,
          }
        );

        if (account) {
          if (this.user.isFirstTimeUser) {
            this._router.navigate([CONSTANTS.ROUTES.FIRST_TIME_SCREEN]);
          } else {
            const route = this._helperService.getDashboardRoute(this.user);
            this._router.navigate(route);
          }
        } else {
          this._authService.login();
        }
      },
    });
  }
}
