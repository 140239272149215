import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterModule,
} from '@angular/router';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import {CEButtonModule} from '@ce-lib/button';
import {CEFooterModule} from '@ce-lib/footer';
import {CEHeaderModule} from '@ce-lib/header';
import {CEModalModule} from '@ce-lib/modal';
import {Store} from '@ngxs/store';
import {Observable, Subject, filter, map, takeUntil, tap} from 'rxjs';
import {AuthService} from './core/auth/auth-service/auth.service';
import {CONSTANTS} from './core/utils/constants/constants';
import {ResetJobLocations} from './core/utils/state/jobs/jobs.action';
import {UserState} from './core/utils/state/user/user.state';
import {IUser} from './shared/interfaces/user';
import {
  EventType as AppEventType,
  ApplicationInsightsService,
} from './shared/services/appInsights/app-insights.service';
import {HelperService} from './shared/services/helper/helper.service';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    CEHeaderModule,
    CEFooterModule,
    CEButtonModule,
    CEModalModule,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Central Connect';
  userName!: string;
  portalClassificationId!: number;
  authenticated = false;
  activeTabIndex = 0;
  currentUser$: Observable<IUser> = inject(Store).select(
    UserState.getCurrentUser
  );
  user!: IUser;
  private readonly _destroying$ = new Subject<void>();
  currentRoute!: string;
  hasError: boolean;
  showInstallModal = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deferredPrompt: any;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _store: Store,
    private _helperService: HelperService,
    private _msalService: MsalService,
    private _msalBroadcastService: MsalBroadcastService,
    private _appInsightsService: ApplicationInsightsService,
    private _swUpdate: SwUpdate
  ) {
    this._router.events
      .pipe(
        tap((event) => {
          if (event instanceof NavigationStart) {
            //calls this stuff when navigation start
            this._appInsightsService.startNavigationEvent(event.url);
          }
          if (event instanceof NavigationEnd) {
            //calls this stuff when navigation ends
            this._appInsightsService.endNavigationEvent(event.url);
            this._appInsightsService.trackPageView();
          }
        }),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.currentRoute =
          this._activatedRoute?.firstChild?.snapshot?.routeConfig?.path || '';
        if (this.currentRoute === 'search') this.activeTabIndex = 1;
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngOnInit() {
    if (this._swUpdate.isEnabled) {
      this._swUpdate.versionUpdates
        .pipe(
          filter(
            (event): event is VersionReadyEvent =>
              event.type === 'VERSION_READY'
          ),
          map((event) => ({
            type: 'UPDATE_AVAILABLE',
            current: event.currentVersion,
            available: event.latestVersion,
          }))
        )
        .subscribe((event) => {
          this._swUpdate
            .activateUpdate()
            .then(() => {
              this._appInsightsService.trackEvent(
                {name: CONSTANTS.EVENT_NAME.SERVICE_WORKER_UPDATE},
                {
                  event: 'Service Worker Update',
                  eventDate: new Date(),
                }
              );
              this.clearCache();
              this.windowReload();
            })
            .catch((err) => {
              this._appInsightsService.trackEvent(
                {name: CONSTANTS.EVENT_NAME.SERVICE_WORKER_UPDATE},
                {
                  event: 'Service Worker Update Failed',
                  eventDate: new Date(),
                }
              );
            });
        });
      this._swUpdate.checkForUpdate().catch((err) => {
        this._appInsightsService.trackError(err);
      });
    }
    this.setLoginDisplay();
    this.currentUser$.subscribe((user) => {
      this.user = {...user};
      this.portalClassificationId = this.user.portalClassificationId;
    });
    if (!this._authService.activeAccountExist()) {
      this._store.dispatch(new ResetJobLocations());
    }

    // Make sure that login display and active setup is not triggered when any of the MSAL interactions are in progress
    this._msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe((result) => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    // Set active account after the login was successful
    this._msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Casting payload as AuthenticationResult to access account
        const payload = result.payload as AuthenticationResult;
        this._authService.setActiveAccount(payload.account);
        this._appInsightsService.setUserId(payload.account.username);
        this.setLoginDisplay();
      });
  }

  setLoginDisplay() {
    this.authenticated = this._authService.getUserAccounts().length > 0;
    this.userName = this._authService.userName;
  }

  checkAndSetActiveAccount(): void {
    let activeAccount = this._msalService.instance.getActiveAccount();

    if (!activeAccount && this._authService.getUserAccounts().length > 0) {
      activeAccount = this._authService.getUserAccounts()[0];
      this._authService.setActiveAccount(activeAccount);
      this._appInsightsService.setUserId(this._authService.userName);
    } else {
      // this._store.dispatch(new ResetJobLocations());
    }
  }

  //Header navigation
  navigate(event: string) {
    this._appInsightsService.trackEvent(
      {name: CONSTANTS.EVENT_NAME.NAVIGATE},
      {
        event: AppEventType.Navigation,
        action: CONSTANTS.EVENT_NAME.NAVIGATE,
        page: CONSTANTS.TELEMETRY.FILENAME.APP_COMPONENT,
        user: this.user,
        email: this.user.employeeEmail,
        navigateEvent: event,
      }
    );
    if (!this.user?.userId && (event === 'dashboard' || event === 'search')) {
      this._router.navigate(['global-error']);
    } else {
      if (event === 'dashboard') {
        this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.goToDashboard(this.user);
        });
      }
      if (event === 'search') {
        this._router.navigateByUrl('/search');
      }
    }

    if (event === 'contactUs') {
      window.open(CONSTANTS.EXTERNAL_URLS.CONTACT_US_FORM, '_blank');
    } else if (event === 'userManual') {
      window.open(CONSTANTS.EXTERNAL_URLS.TUTORIALS, '_blank');
    } else if (event === 'troubleshooting') {
      window.open(CONSTANTS.EXTERNAL_URLS.TROUBLESHOOTING_GUIDE, '_blank');
    } else if (event === 'legalInfo') {
      window.open(CONSTANTS.EXTERNAL_URLS.TERMS_AND_CONDITIONS, '_blank');
    }
  }

  logout() {
    this._appInsightsService.trackEvent(
      {name: CONSTANTS.EVENT_NAME.LOG_OUT},
      {
        event: AppEventType.Navigation,
        action: CONSTANTS.EVENT_NAME.LOG_OUT,
        page: CONSTANTS.TELEMETRY.FILENAME.APP_COMPONENT,
        user: this.user,
        email: this.user.employeeEmail,
      }
    );
    this._store.dispatch(new ResetJobLocations());
    localStorage.clear();
    this._authService.logout();
  }

  goToDashboard(user: IUser) {
    const urlToNavigate = this._helperService.getDashboardRoute(user);
    this._appInsightsService.trackEvent(
      {name: CONSTANTS.EVENT_NAME.NAVIGATE},
      {
        event: AppEventType.Navigation,
        action: CONSTANTS.EVENT_NAME.NAVIGATE,
        page: CONSTANTS.TELEMETRY.FILENAME.APP_COMPONENT,
        user: this.user,
        email: this.user.employeeEmail,
        urlToNavigate,
      }
    );
    this._router.navigate(urlToNavigate);
  }

  /**
   * Clear Stale Cache and let SW fetch the updated information from Server
   */
  private clearCache() {
    if (window.caches) {
      caches.keys().then((cacheKeys) => {
        return Promise.all(
          cacheKeys.map((key) => {
            return caches.delete(key);
          })
        );
      });
    }
  }

  windowReload() {
    window.location.reload();
  }

  ngAfterViewInit() {
    this.promptInstallApplication();
  }

  onBeforeInstallPrompt(e) {
    e.preventDefault();
    const installFlag = JSON.parse(localStorage.getItem('showInstallPromote'));
    if (installFlag !== null) {
      this.showInstallModal = installFlag;
    } else {
      this.showInstallModal = true;
    }
    this.deferredPrompt = e;
  }

  promptInstallApplication() {
    window.addEventListener('beforeinstallprompt', (e) => {
      this.onBeforeInstallPrompt(e);
    });
  }

  showInstallPrompt() {
    this.deferredPrompt?.prompt();
    this.deferredPrompt?.userChoice.then((choiceResult) => {
      this._appInsightsService.trackEvent(
        {name: CONSTANTS.EVENT_NAME.INSTALL_PWA},
        {
          event: AppEventType.ButtonClick,
          action:
            choiceResult.outcome === 'accepted'
              ? CONSTANTS.EVENT_NAME.INSTALL_PWA
              : CONSTANTS.EVENT_NAME.OPT_OUT_INSTALL_PWA,
          user: this.userName,
          email: this.user.employeeEmail,
        }
      );
      if (choiceResult.outcome === 'accepted') {
        this.closeInstallModal();
      } else {
        this.closeInstallModal();
      }
      this.deferredPrompt = null;
    });
  }

  closeInstallModal() {
    //this.store.dispatch(new AppActions.DisableInstallPromote());
    localStorage.setItem('showInstallPromote', 'false');
    this.showInstallModal = false;
  }

  onDashboard(): boolean {
    return (
      this._router.url !== '' &&
      (this._router.url.includes(
        this._helperService.getDashboardRoute(this.user).join('/')
      ) ||
        this._router.url === '/')
    );
  }
}
