import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, RouterModule} from '@angular/router';

import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsModule} from '@ngxs/store';
import {environment} from 'src/environments/environment';
import {routes} from './app.routes';
import {MsalConfigModule} from './core/auth/msal-config/msal-config.module';
import {ApimKeyInterceptor} from './core/interceptors/apim.interceptor';
import {GlobalErrorInterceptor} from './core/interceptors/global-error.interceptor';
import {AssetsState} from './core/utils/state/assets/assets.state';
import {StructuralDefectState} from './core/utils/state/defects/defects.state';
import {JobDetailsState} from './core/utils/state/job-details/job-details.state';
import {JobsState} from './core/utils/state/jobs/jobs.state';
import {MediasState} from './core/utils/state/medias/medias.state';
import {SearchState} from './core/utils/state/search/search.state';
import {UserState} from './core/utils/state/user/user.state';

const ISIFRAME = window !== window.parent && !window.opener;


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApimKeyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalErrorInterceptor,
      multi: true,
    },
    importProvidersFrom(
      RouterModule.forRoot(routes, {
        initialNavigation: !ISIFRAME ? 'enabledBlocking' : 'disabled',
        scrollPositionRestoration: 'enabled',
      }),
      MsalConfigModule,
      NgxsModule.forRoot(
        [
          UserState,
          JobsState,
          JobDetailsState,
          MediasState,
          AssetsState,
          StructuralDefectState,
          SearchState,
        ],
        {
          developmentMode: !environment.production,
        }
      ),
      NgxsLoggerPluginModule.forRoot({disabled: environment.production}),
      NgxsStoragePluginModule.forRoot({keys: '*'}),
      ServiceWorkerModule.register('./ngsw-worker.js', {
        enabled: environment.production,
      })
    ),
  ],
};
