<div class="main">
  <!-- <ce-spinner
    type="loader"
    [overlay]="false"
    brand="ce"
    size="large"
    [show]="true"
  ></ce-spinner> -->
  <ce-spinner
    type="spinner"
    [show]="true"
  ></ce-spinner>
</div>
